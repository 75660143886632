export const environmentData = {
  run: 'production',
  development: {
    clientId: 'ce03ce39-9810-4d47-89d7-b3a4d28b309d',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid ce03ce39-9810-4d47-89d7-b3a4d28b309d/User.Impersonation',
    appInsightConnectionString:
      'InstrumentationKey=9c7b92eb-2909-4a1d-9241-c91d3617a3ab;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    apiBaseURL: 'https://integrationmonitoringdev.azurewebsites.net',
    apiBaseURLBam: 'https://integrationmonitoringdev.azurewebsites.net',
  },
  staging: {
    clientId: 'ce03ce39-9810-4d47-89d7-b3a4d28b309d',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid ce03ce39-9810-4d47-89d7-b3a4d28b309d/User.Impersonation',
    appInsightConnectionString:
      'InstrumentationKey=73ba61b6-af6e-47cd-a9f1-e20711b90ca5;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    apiBaseURL: 'https://dev-api-execdb-ps.azurewebsites.net/api',
    apiBaseURLBam: 'https://integrationmonitoringprod.azurewebsites.net',
  },
  production: {
    clientId: 'ce03ce39-9810-4d47-89d7-b3a4d28b309d',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid ce03ce39-9810-4d47-89d7-b3a4d28b309d/User.Impersonation',
    appInsightConnectionString:
      'InstrumentationKey=73ba61b6-af6e-47cd-a9f1-e20711b90ca5;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    apiBaseURL: 'https://integrationmonitoringprod.azurewebsites.net',
    apiBaseURLBam: 'https://integrationmonitoringprod.azurewebsites.net',
  },
}
